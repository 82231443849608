import { setupWorker } from 'msw'

import { handlers } from './handlers'

const worker = setupWorker(...handlers)

export const start = () =>
  void worker.start({
    serviceWorker: {
      url: '/mock-service-worker.js',
    },
  })
