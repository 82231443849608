import loadable from '@loadable/component'
import { RouteObject } from 'react-router-dom'

const Home = loadable(() => import('./home'))
const NotFound = loadable(() => import('./not-found'))

export const routes: RouteObject[] = [
  {
    caseSensitive: true,
    element: <Home />,
    path: '/',
  },
  {
    caseSensitive: true,
    element: <NotFound />,
    path: '*',
  },
]
