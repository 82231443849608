import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import * as sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { Amplify, Auth } from 'aws-amplify'

import { Root } from './components/root'
import { routes } from './routes'
import {
  apiUrl,
  appShortName,
  appVersion,
  authRegion,
  isApiMocked,
  isDebugMode,
  isProduction,
  sentryDataSourceName,
  userPoolId,
  userPoolWebClientId,
  awsS3BucketName,
  awsRegion,
  awsCognitoIdentityPoolId,
} from './config'
import '@aws-amplify/ui-react/styles.css'
import { getSetUser, useUserStore } from 'routes/home/stores/user'
import { CircularProgress } from '@mui/material'
import { spinner } from 'routes/home/styles'
import { css } from '@emotion/css'
import { useTheme, Theme } from '@mui/material/styles'

const content = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

if (isApiMocked) {
  require('./mocks/worker').start() // eslint-disable-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-var-requires
}

Amplify.configure({
  Auth: {
    identityPoolId: awsCognitoIdentityPoolId,
    userPoolId,
    userPoolWebClientId,
    region: authRegion,
  },
  Storage: {
    AWSS3: {
      bucket: awsS3BucketName,
      region: awsRegion,
    },
  },
})

sentry.init({
  debug: isDebugMode,
  dsn: sentryDataSourceName,
  environment: isProduction ? 'production' : 'development',
  integrations: [
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: apiUrl ? [new URL(apiUrl).host] : undefined,
    }),
  ],
  release: `${appShortName}@${appVersion}`,
  tracesSampleRate: 0.25,
})

const onError = (error: unknown) => {
  sentry.captureException(error)

  return
}

const client = new QueryClient({
  mutationCache: new MutationCache({ onError }),
  queryCache: new QueryCache({ onError }),
})

const App = () => {
  const setUser = useUserStore(getSetUser)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const theme = useTheme<Theme>()

  useEffect(() => {
    const updateUserStore = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const cognitoUser: {
          username: string
          attributes: { name: string; email: string }
        } = await Auth.currentAuthenticatedUser()
        setUser({
          userId: cognitoUser.username,
          fullName: cognitoUser.attributes.name,
          email: cognitoUser.attributes.email,
        })
      } catch (error) {
        console.info(error)
      } finally {
        setIsLoading(false)
      }
    }
    void updateUserStore()
  }, [setUser])

  return (
    <QueryClientProvider client={client}>
      {/* <Button onClick={() => void Auth.signOut()}>Sign Out</Button> */}
      <BrowserRouter>
        {isLoading ? (
          <div className={content}>
            <CircularProgress
              size={38}
              color={'primary'}
              className={spinner(theme)}
            />
          </div>
        ) : (
          <Root routes={routes} />
        )}
      </BrowserRouter>
    </QueryClientProvider>
  )
}

const container = document.querySelector('#root')
const root = createRoot(container!)
root.render(<App />)
