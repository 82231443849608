import create from 'zustand'

interface DocumentState {
  hide: () => void
  isVisible: boolean
  show: () => void
}

export const getHideDocument = ({ hide }: DocumentState) => hide

export const getIsDocumentVisible = ({ isVisible }: DocumentState) => isVisible

export const getShowDocument = ({ show }: DocumentState) => show

export const useDocumentStore = create<DocumentState>((set) => ({
  hide: () => set({ isVisible: false }),
  isVisible: false,
  show: () => set({ isVisible: true }),
}))
