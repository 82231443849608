import { Auth, SignUpParams } from '@aws-amplify/auth'
import { UserDetails } from 'data/api'
import { User } from 'data/auth'

import {
  getSetUser,
  getSetUserDetails,
  getUser,
  getUserDetails,
  useUserStore,
} from 'routes/home/stores/user'
import { useCreateUserDetails, useGetUserDetails } from './api'

export const useAuthenticationService = () => {
  const setUser = useUserStore(getSetUser)
  const handleSignUp = async (formData: SignUpParams) => {
    const authResult = await Auth.signUp(formData)
    console.info('Sign up complete::', authResult)
    setUser({ userId: '', email: '', avatarId: '', fullName: '' })
    return authResult
  }
  const user = useUserStore(getUser)
  const setUserDetails = useUserStore(getSetUserDetails)
  const userDetails = useUserStore(getUserDetails)

  const getUserDetailsApi = useGetUserDetails({
    pathParams: { userId: user?.userId || '' },
    queryKey: [user?.userId],
  }).refetch

  const createUserDetails = useCreateUserDetails({
    pathParams: { userId: user?.userId || '' },
    mutationKey: [user?.userId],
  }).mutateAsync

  const handleSignIn = async (formData: {
    username: string
    password: string
    validationData: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }) => {
    const authResult = await Auth.signIn(formData) // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    console.info('Sign in', authResult)
    const userId = authResult.attributes.sub as string // eslint-disable-line @typescript-eslint/no-unsafe-member-access
    const fullName = authResult.attributes.name as string // eslint-disable-line @typescript-eslint/no-unsafe-member-access
    const email = authResult.attributes.email as string // eslint-disable-line @typescript-eslint/no-unsafe-member-access
    const user: User = { userId, email, avatarId: '', fullName }
    setUser(user)
    return authResult // eslint-disable-line @typescript-eslint/no-unsafe-return
  }

  const handleSubmitCode = async (email: string, code: string) => {
    const response = await Auth.confirmSignUp(email, code) // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    setUser({ userId: '', email: email, avatarId: '', fullName: '' })
    return response // eslint-disable-line @typescript-eslint/no-unsafe-return
  }

  const loadUserDetails = (user: User) => {
    return new Promise((resolve, reject) => {
      void (async () => {
        if (user && !userDetails) {
          try {
            console.warn('getting user details:', user)
            const userDetailsResp = await getUserDetailsApi()
            let userDetailsData = userDetailsResp.data
            console.warn('user:', user)
            console.warn('userDetails:', userDetailsResp)
            if (!userDetailsData) {
              // There is no user details saved so save the user detail first
              console.warn('creating user details')
              await createUserDetails({
                userId: user.userId,
                userEmail: user.email,
              })
              console.warn('geting user details')
              // TODO: Instead of fetching userdetails again, return it from create api
              const getDetailsResponse = await getUserDetailsApi()
              userDetailsData = getDetailsResponse.data
            }
            // transforming data
            const userDetails: UserDetails = {
              userId: user.userId,
              userEmail: user.email,
              id: userDetailsData?.id,
              agentId: userDetailsData?.agent_id,
              avatarId: userDetailsData?.avatar_id,
              avatarKey: userDetailsData?.avatar_key,
              avatarName: userDetailsData?.avatar_name,
              avatarFileKey: userDetailsData?.avatar_file_key,
              speechModelId: userDetailsData?.speech_model_id,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              avatarGender: userDetailsData?.avatar_gender,
            }
            console.warn('seting user details:', userDetails)
            setUserDetails(userDetails)
            resolve(userDetails)
          } catch (error) {
            console.warn('error:', error)
            reject(error)
          }
        } else {
          console.warn('user doesnot exists:', user)
          reject('user doesnot exists')
        }
      })()
    })
  }

  return { handleSignIn, handleSignUp, handleSubmitCode, loadUserDetails }
}
