import { css } from '@emotion/react'
import { createTheme } from '@mui/material/styles'
import { dark, light } from '@mui/material/styles/createPalette'

export const globalStyles = css`
  html {
    height: 100%;
  }

  body {
    height: 100%;
    min-height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
`

export const lightTheme = createTheme({
  typography: {
    fontFamily: 'Dosis',
  },
  palette: {
    mode: 'light',
    primary: {
      contrastText: '#fff',
      dark: '#16222c',
      light: '#4c5a66',
      main: '#1eafb7',
    },

    secondary: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#acaba9',
      light: '#f8f7f4',
      main: '#f7f5f2',
    },

    info: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#53B4B0',
      light: '#B8E0E6',
      main: '#27D4DF',
    },
    ...light,
  },
})

export const darkTheme = createTheme(lightTheme, {
  palette: {
    mode: 'dark',
    ...dark,
  },
})
