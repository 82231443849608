import { css } from '@emotion/css'
import { alpha, Theme, darken, lighten } from '@mui/material/styles'
import { buttonClasses } from '@mui/material/Button'
import { svgIconClasses } from '@mui/material/SvgIcon'
import { paperClasses } from '@mui/material/Paper'

export const viewHeader = ({ breakpoints, spacing }: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  padding: ${spacing(2)};

  ${breakpoints.up('lg')} {
    padding: ${spacing(3)};
  }
`

export const viewLogo = ({ breakpoints, palette }: Theme) => css`
  display: flex;
  height: 80px;
  color: ${palette.primary.main};
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
    drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));

  ${breakpoints.up('sm')} {
    height: 80px;
  }
`

export const viewFeedbackButton = ({ breakpoints, spacing }: Theme) => css`
  ${breakpoints.down('sm')} {
    margin-left: ${spacing(2)};
  }
`
export const controlWrapper = ({ breakpoints }: Theme) => css`
  display: flex;
  flex-grow: 1;

  ${breakpoints.up('sm')} {
    flex-grow: 0;
  }
`

export const control = ({ breakpoints, typography }: Theme) => css`
  &.${buttonClasses.root} {
    flex-grow: 1;
    font-weight: ${typography.caption.fontWeight};
    font-size: ${typography.caption.fontSize};
    border-radius: 0;

    ${breakpoints.up('sm')} {
      font-weight: ${typography.button.fontWeight};
      font-size: ${typography.button.fontSize};
      border-radius: 4px;
    }
  }
`

export const controlContent = ({ breakpoints }: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 72px;
  white-space: nowrap;

  ${breakpoints.up('sm')} {
    min-width: 88px;
  }
`

export const controlIcon = ({ palette, breakpoints }: Theme) => css`
  &.${svgIconClasses.root} {
    width: 24px;
    height: 24px;
    color: ${palette.primary.main};

    ${breakpoints.up('sm')} {
      width: 32px;
      height: 32px;
    }
  }
`

export const header = ({ breakpoints, palette }: Theme) => css`
  ${breakpoints.down('sm')} {
    background-color: ${palette.primary.main};
  }
`

export const logo = ({ breakpoints }: Theme) => css`
  ${breakpoints.down('sm')} {
    color: #fff;
  }
`

export const spinner = ({ spacing }: Theme) => css`
  margin-left: ${spacing(1)};
`

export const paper = ({ breakpoints, palette }: Theme) => css`
  &.${paperClasses.root} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${alpha(palette.primary.main, 0.75)};
    border-radius: 1rem;
    border: 0.2rem solid ${lighten(palette.primary.main, 0.25)};
    box-shadow: 0.05rem 0.05rem ${darken(palette.primary.main, 0.25)};

    ${breakpoints.down('md')} {
      min-width: 336px;
      max-width: 384px;
    }

    ${breakpoints.down('sm')} {
      width: 100%;
      min-width: unset;
      max-width: unset;
      border-radius: unset;
      box-shadow: unset;
      transition: unset;
    }

    ${breakpoints.up('md')} {
      flex-direction: row;
      width: 560px;
    }
  }
`
