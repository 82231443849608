import { Auth } from 'aws-amplify'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import ky, { BeforeRequestHook, SearchParamsOption as SearchParams } from 'ky'
import { generatePath, Params as PathParams } from 'react-router-dom'

import type { LoginResponse } from 'data/auth'
import {
  apiUrl,
  authAccountName,
  avatarSdkClientCredentials,
  avatoidsApiEndpoint,
  avatoidsApiPublicEndpoint,
  speechModelApiUrl,
} from 'config'
import { AvatarSdkTokenOutput } from 'data/avatar-sdk'

interface LoginPathParams extends PathParams {
  accountName: string
}

const generateLoginPath = (params: LoginPathParams) =>
  generatePath('login/account/:accountName', params)

const setCognitoAuthorizationHeader: BeforeRequestHook = async (request) => {
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken()
  return request.headers.set('Authorization', `${token}`)
}

const REQUEST_TIMEOUT = 2 * 60 * 1000

type Fetcher = () => typeof ky

const loginPath = generateLoginPath({ accountName: authAccountName })
const apiV1 = () => {
  let token = '.e30='

  const fetch = () => {
    const setAuthorizationHeader: BeforeRequestHook = async (
      request,
      { prefixUrl }
    ) => {
      const { exp } = jwtDecode<JwtPayload>(token)

      if (!exp || exp * 1000 <= Date.now()) {
        const data = await ky
          .post(loginPath, { prefixUrl })
          .json<LoginResponse>()

        token = data.token
      }

      return request.headers.set('Authorization', `Bearer ${token}`)
    }

    return ky.create({
      timeout: REQUEST_TIMEOUT,
      hooks: { beforeRequest: [setAuthorizationHeader] },
      prefixUrl: `${apiUrl}/api/v1`,
    })
  }

  return fetch
}
const speechModelApi = () => {
  return () => {
    return ky.create({
      timeout: REQUEST_TIMEOUT,
      prefixUrl: `${speechModelApiUrl}`,
    })
  }
}

const apiAvatarSdk = () => {
  let token = localStorage.getItem('AVATAR_SDK_TOKEN') || ''
  let tokenExp = +(localStorage.getItem('AVATAR_SDK_TOKEN_EXPIRATION') || '0')

  const fetch = () => {
    const setAuthorizationHeader: BeforeRequestHook = async (
      request,
      { prefixUrl }
    ) => {
      if (!token || !tokenExp || tokenExp <= Date.now()) {
        const formData = new FormData()
        formData.append('grant_type', 'client_credentials')
        const data = await ky
          .post('o/token/', {
            prefixUrl,
            body: formData,
            headers: {
              Authorization: `Basic ${avatarSdkClientCredentials}`,
            },
          })
          .json<AvatarSdkTokenOutput>()

        token = data.access_token
        tokenExp = Date.now() + data.expires_in * 1000
        localStorage.setItem('AVATAR_SDK_TOKEN', token)
        localStorage.setItem('AVATAR_SDK_TOKEN_EXPIRATION', tokenExp.toString())
      }

      return request.headers.set('Authorization', `Bearer ${token}`)
    }

    return ky.create({
      timeout: REQUEST_TIMEOUT,
      hooks: { beforeRequest: [setAuthorizationHeader] },
      prefixUrl: 'https://api.avatarsdk.com',
    })
  }

  return fetch
}
const apiAvatoids = () => {
  const endpoint = avatoidsApiEndpoint
  const fetch = () => {
    return ky.create({
      timeout: REQUEST_TIMEOUT,
      hooks: { beforeRequest: [setCognitoAuthorizationHeader] },
      prefixUrl: endpoint,
    })
  }

  return fetch
}

const apiPublicAvatoids = () => {
  const endpoint = avatoidsApiPublicEndpoint
  const fetch = () => {
    return ky.create({
      timeout: REQUEST_TIMEOUT,
      prefixUrl: endpoint,
    })
  }

  return fetch
}

const patch =
  (fetcher: Fetcher) =>
  <TSearchParams extends SearchParams>(
    path: string,
    searchParams?: TSearchParams
  ) =>
  <TData, TBody>(body: TBody) =>
    fetcher().patch(path, { json: body, searchParams }).json<TData>()

const put =
  (fetcher: Fetcher) =>
  <TSearchParams extends SearchParams>(
    path: string,
    searchParams?: TSearchParams
  ) =>
  <TData, TBody>(body: TBody) =>
    fetcher().put(path, { json: body, searchParams }).json<TData>()

const post =
  (fetcher: Fetcher) =>
  <TSearchParams extends SearchParams>(
    path: string,
    searchParams?: TSearchParams
  ) =>
  <TData, TBody>(body: TBody) =>
    fetcher().post(path, { json: body, searchParams }).json<TData>()

const get =
  (fetcher: Fetcher) =>
  <TData, TSearchParams extends SearchParams = ''>(
    path: string,
    searchParams?: TSearchParams
  ) =>
    fetcher().get(path, { searchParams }).json<TData>()

const deleteRequest =
  (fetcher: Fetcher) =>
  <TData, TBody, TSearchParams extends SearchParams>(
    path: string,
    searchParams?: TSearchParams,
    body?: TBody
  ) =>
    fetcher().delete(path, { json: body, searchParams }).json<TData>()

const getResponse =
  (fetcher: Fetcher) =>
  <TSearchParams extends SearchParams>(
    path: string,
    searchParams?: TSearchParams
  ) =>
    fetcher().get(path, { searchParams })

const fetcherPostFormData =
  (fetcher: Fetcher) =>
  <TSearchParams extends SearchParams>(
    path: string,
    searchParams?: TSearchParams
  ) =>
  async <TData, TBody>(body: TBody | { [key: string]: string }) => {
    const formData = new FormData()
    if (body) {
      Object.keys(body as TBody).map((key) => {
        formData.append(key, (body as { [key: string]: string })[key] as string)
      })
    }

    return fetcher()
      .post(path, {
        body: formData,
        searchParams,
      })
      .json<TData>()
  }

export const avatarSdkPost = fetcherPostFormData(apiAvatarSdk())
export const avatarSdkGetRawResponse = getResponse(apiAvatarSdk())
export const avatarSdkGet = get(apiAvatarSdk())

export const apiV1Get = get(apiV1())
export const apiV1Post = post(apiV1())
export const apiV1Delete = deleteRequest(apiV1())
export const apiV1Patch = patch(apiV1())

export const speechModelApiGet = get(speechModelApi())
export const speechModelApiPost = post(speechModelApi())

export const apiAvatoidsPut = put(apiAvatoids())
export const apiAvatoidsPost = post(apiAvatoids())
export const apiAvatoidsGet = get(apiAvatoids())

export const apiAvatoidsPublicPost = post(apiPublicAvatoids())
