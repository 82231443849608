import { v4 as uuid } from 'uuid'
import create from 'zustand'
import { persist } from 'zustand/middleware'

interface DeviceState {
  id: string
}

export const getDeviceId = ({ id }: DeviceState) => id

export const useDeviceStore = create(
  persist<DeviceState>(() => ({ id: uuid() }), { name: 'device', version: 0 })
)
