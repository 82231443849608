import CssBaseline from '@mui/material/CssBaseline'
import * as sentry from '@sentry/react'
import { useEffect, useState } from 'react'
// import { useEffect, StrictMode, useState } from 'react'
import { useRoutes, RouteObject } from 'react-router-dom'
import { Global } from '@emotion/react'
import { css } from '@emotion/css'
import { useTheme, Theme, ThemeProvider } from '@mui/material/styles'

import { globalStyles, lightTheme } from 'styles'
import { getDeviceId, useDeviceStore } from 'stores/device'
import {
  getHideDocument,
  getShowDocument,
  useDocumentStore,
} from 'stores/document'
import { getSessionId, useSessionStore } from 'stores/session'
import {
  getSetUserDetails,
  getUser,
  useUserStore,
} from 'routes/home/stores/user'
import { useCreateUserDetails, useGetUserDetails } from 'hooks/api'
import { spinner } from 'routes/home/styles'
import { CircularProgress } from '@mui/material'
import { useAuthenticationService } from 'hooks/auth'
import { User } from 'data/auth'

const content = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

interface RootProps {
  routes: RouteObject[]
}

export const Root = ({ routes }: RootProps) => {
  const theme = useTheme<Theme>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const deviceId = useDeviceStore(getDeviceId)
  const route = useRoutes(routes)
  const sessionId = useSessionStore(getSessionId)
  const user = useUserStore(getUser)
  const setUserDetails = useUserStore(getSetUserDetails)
  const hideDocument = useDocumentStore(getHideDocument)
  const showDocument = useDocumentStore(getShowDocument)
  const { loadUserDetails } = useAuthenticationService()
  const getUserDetails = useGetUserDetails({
    pathParams: { userId: user?.userId || '' },
  }).refetch

  const createUserDetails = useCreateUserDetails({
    pathParams: { userId: user?.userId || '' },
  }).mutateAsync

  useEffect(() => {
    const getInitialUserDetails = async () => {
      try {
        console.warn('calling load user details')
        // eslint-disable-line @typescript-eslint/no-unsafe-member-access
        await loadUserDetails(user as User)
      } catch (error) {
        console.warn('error loading user details', error)
      } finally {
        setIsLoading(false)
      }
    }
    if (user) {
      void getInitialUserDetails()
    } else {
      setIsLoading(false)
    }
  }, [getUserDetails, createUserDetails, setUserDetails])

  useEffect(() => {
    const listen = () =>
      document.visibilityState === 'hidden' ? hideDocument() : showDocument()

    document.addEventListener('visibilitychange', listen)

    listen()

    return () => document.removeEventListener('visibilitychange', listen)
  }, [hideDocument, showDocument])

  useEffect(() => {
    sentry.setTags({
      'device.id': deviceId,
      'session.id': sessionId,
    })
  }, [deviceId, sessionId])

  return (
    // ToDo: Removed Strict mode for now to test performance
    // <StrictMode>
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Global styles={globalStyles} />
      {isLoading ? (
        <div className={content}>
          <CircularProgress
            size={38}
            color={'primary'}
            className={spinner(theme)}
          />
        </div>
      ) : (
        route
      )}
    </ThemeProvider>
    // </StrictMode>
  )
}
