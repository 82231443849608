import create from 'zustand'

import { User } from 'data/auth'
import { UserDetails } from 'data/api'

interface UserState {
  user?: User
  userDetails: UserDetails | undefined
  status: UserStatus
  setUser: (user: User) => void
  setUserDetails: (userDetails: UserDetails) => void
  setStatus: (status: UserStatus) => void
  updateSpeechModelId: (speechModelId: string) => void
  clearUserData: () => void
}

enum UserStatus {
  Initial = 'initial',
  Loading = 'loading',
  Loaded = 'loaded',
  LoggedOut = 'loggedOut',
}

export const getUser = ({ user }: UserState) => user

export const getUserDetails = ({ userDetails }: UserState) => userDetails

export const getSetUser = ({ setUser }: UserState) => setUser

export const getSetUserDetails = ({ setUserDetails }: UserState) =>
  setUserDetails

export const getUpdateSpeechModelId = ({ updateSpeechModelId }: UserState) =>
  updateSpeechModelId

export const getStatus = ({ status }: UserState) => status

export const getSetStatus = ({ setStatus }: UserState) => setStatus

export const getClearUserData = ({ clearUserData }: UserState) => clearUserData

export const useUserStore = create<UserState>((set) => ({
  user: undefined,
  // userDetails: {
  //   userId: '50475cd9-7fe3-46fc-a73b-9dc3728ee154',
  //   userEmail: 'zohaib+1@aifoundation.com',
  //   avatarId: '92f8a221-cd9f-4e13-9c66-958f136d913f',
  //   avatarKey: '92f8a221-cd9f-4e13-9c66-958f136d913f',
  //   avatarName: 'test-avatar-1',
  //   avatarFileKey: 'avatar-sdk-files/1660233269305.zip',
  // },
  userDetails: undefined,
  status: UserStatus.Initial,
  setStatus: (status: UserStatus) => set({ status }),
  setUser: (user: User) => set({ user, status: UserStatus.Loaded }),
  updateSpeechModelId: (speechModelId: string) =>
    set((state: UserState) => {
      const newState: Partial<UserState> = {
        userDetails: { ...state.userDetails, speechModelId } as UserDetails,
      }
      return newState
    }),
  setUserDetails: (userDetails: UserDetails) =>
    set({ userDetails, status: UserStatus.Loaded }),
  clearUserData: () =>
    set({
      user: undefined,
      userDetails: undefined,
      status: UserStatus.LoggedOut,
    }),
}))
