//TODO: Merge cdk output with dotenv to have one source of truth
import backendConfigDev from './backend-config-dev.json'
import backendConfigProd from './backend-config-prod.json'

const backendConfig =
  process.env.REACT_APP_ENV === 'prod' ? backendConfigProd : backendConfigDev

export const appAuthor = process.env.REACT_APP_AUTHOR ?? 'AI Media Lab'
export const appName = process.env.REACT_APP_NAME ?? 'Digital Self'
export const appShortName =
  process.env.REACT_APP_SHORT_NAME ?? 'project-aif-portal'
export const appVersion = process.env.REACT_APP_VERSION ?? '0.12.0'

export const publicUrl = process.env.PUBLIC_URL ?? 'http://localhost'

export const awsRegion = backendConfig.region

export const awsCognitoIdentityPoolId = backendConfig.identityPoolId

export const dialogBotName = process.env.REACT_APP_DIALOG_BOT_NAME ?? 'man'

export const avatarCreationGuideBotName =
  process.env.REACT_APP_DIALOG_BOT_NAME ?? 'brittani'

export const twinToTwinBotName =
  process.env.REACT_APP_TWIN_TO_TWIN_BOT_NAME ?? 'npc-to-npc'
export const userToTwinBotName =
  process.env.REACT_APP_USER_TO_TWIN_BOT_NAME ?? 'user-to-npc'

export const awsS3BucketName = backendConfig.storagebucketname

export const mediaCloudfrontDomain = backendConfig.distributionDomain
  ? `https://${backendConfig.distributionDomain}`
  : ''

export const avatarSdkClientCredentials =
  process.env.REACT_APP_AVATAR_SDK_CLIENT_CREDENTIALS ?? ''

export const sentryDataSourceName =
  process.env.REACT_APP_SENTRY_DATA_SOURCE_NAME ?? ''

console.warn('Environment:', process.env.NODE_ENV)

export const apiUrl =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL ?? ''
    : ''

export const speechModelApiUrl =
  process.env.REACT_APP_SPEECH_MODEL_API_URL ?? 'http://52.40.117.102'

export const authAccountName = process.env.REACT_APP_AUTH_ACCOUNT_NAME ?? 'aif'

export const defaultLanguageCode =
  process.env.REACT_APP_DEFAULT_LANGUAGE_CODE ?? 'en-US'

export const tooltipEnterDelay = 700

export const isProduction = /^prod(uction)?$/.test(
  process.env.REACT_APP_ENV ?? process.env.NODE_ENV
)

export const userPoolId = backendConfig.userPoolId

export const authRegion = backendConfig.region

export const userPoolWebClientId = backendConfig.userPoolClientId

export const avatoidsApiEndpoint = backendConfig.apiUrl

export const avatoidsApiPublicEndpoint = backendConfig.publicApiUrl

export const isDebugMode = isProduction
  ? false
  : process.env.REACT_APP_DEBUG === 'true'

export const isApiMocked = isProduction
  ? false
  : process.env.REACT_APP_MOCK_API === 'true'

export const defaultSpeechModelDomain = 'https://d13n2vwdryhki3.cloudfront.net'

export const defaultAegentTypeId = '9813ea5f-9e72-44d7-bc33-ede3872ec230'
export const defaultAgentProviderId = '67fba4ad-f6a9-4784-8130-d3680c254401'
export const defaultNluAgentId = '5e108500-3d36-460d-aafc-04dde9a00ec4'
export const defaultSpeechModelEpoch = 15

export const AngelaSpeechAgentId = 'd7172ee4-b6a1-4464-93a8-a794cee83f9c'
export const AnthonySpeechAgentId = '2205e25a-a5bc-47ab-ad80-a33fe2bf0e42'
export const JackSpeechAgentId = 'ad4c20a0-e459-4f3a-93b1-4c13d7195fbc'
export const LewisSpeechAgentId = '78a34880-e898-4948-b790-7253e760f0a6'
export const MalenieSpeechAgentId = '0ee06700-22f6-4522-a58d-b895cc4a2cd3'
export const PaulSpeechAgentId = 'a0c8e3c2-eb76-402d-9dd6-b73b768dd761'
export const AllySpeechAgentId = '7e6458c1-ed10-47b3-bb73-bac4e8466a2b'
