import { useMutation, useQuery } from '@tanstack/react-query'
import { generatePath, Params as PathParams } from 'react-router-dom'
import { HTTPError } from 'ky'

// import {
//   AvatarSdkCreateAvatarOutput,
//   AvatarSdkCreateAvatarInput,
// } from 'data/avatar-sdk'
import {
  apiAvatoidsGet,
  apiAvatoidsPost,
  apiAvatoidsPublicPost,
  apiAvatoidsPut,
} from 'utils/fetch'
import { UseQueryOptions, UseMutationOptions } from './common'
import { UserDetails, UserDetailsDB } from 'data/api'

interface UserDetailsPathParams extends PathParams {
  userId?: string
  userDetailsId?: string
}

// export const useApiCreateAvatar = (
//   options: UseMutationOptions<
//     AvatarSdkCreateAvatarOutput,
//     AvatarSdkCreateAvatarInput
//   >
// ) => {
//   return useMutation(apiAvatoidsPost(''), {
//     mutationKey: [''],
//     ...options,
//   })
// }

const userDetailsPath = 'user/:userId/details'

export const useCreateUserDetails = ({
  pathParams: { userId },
  ...restOptions
}: UseMutationOptions<UserDetails, UserDetails, UserDetailsPathParams>) => {
  const path = generatePath(userDetailsPath, { userId })

  return useMutation(apiAvatoidsPost(path), {
    mutationKey: [path],
    ...restOptions,
  })
}

const updateUserDetailsPath = 'user/:userId/details/:userDetailsId'

export const useUpdateUserDetails = ({
  pathParams: { userId, userDetailsId },
  ...restOptions
}: UseMutationOptions<UserDetails, UserDetails, UserDetailsPathParams>) => {
  const path = generatePath(updateUserDetailsPath, { userId, userDetailsId })

  return useMutation(apiAvatoidsPut(path), {
    mutationKey: [path],
    ...restOptions,
  })
}

export const useGetUserDetails = ({
  pathParams: { userId },
  ...restOptions
}: UseQueryOptions<UserDetailsDB, UserDetailsPathParams>) => {
  const path = generatePath(userDetailsPath, { userId })

  return useQuery<UserDetailsDB, HTTPError>({
    enabled: false,
    retry: false,
    onError: (error) => console.error(error),
    queryKey: [path],
    ...restOptions,
    queryFn: () => apiAvatoidsGet(path),
  })
}

export const confirmUser = async (userId: string, code: string) => {
  const path = generatePath('user/:userId/confirm', { userId })
  const result = await apiAvatoidsPublicPost(path)({
    code,
  })
  console.info('result from confirm user', result)
  return result
}
