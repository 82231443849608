import create from 'zustand'
import { v4 as uuid } from 'uuid'

interface SessionState {
  id: string
}

export const getSessionId = ({ id }: SessionState) => id

export const useSessionStore = create<SessionState>(() => ({
  id: uuid(),
}))
